/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { graphql, navigate } from 'gatsby';
import { Row, Col, Container, Nav, CloseButton } from 'react-bootstrap';
import styled from 'styled-components';

import Layout from '@layouts/layout';
import SEO from '@components/seo';
import BannerContainer from '@components/bannerContainer';

const OrderedList = styled.ol`
  counter-reset: list;
  & > li {
    list-style: none;
    margin-bottom: 1rem;
    &:before {
      content: counter(list, decimal) '. ';
      counter-increment: list;
      font-weight: bold;
    }
  }
`;

const OrderedAlphaList = styled.ol`
  counter-reset: list;
  & > li {
    list-style: none;
    margin-bottom: 1rem;
    &:before {
      content: counter(list, lower-alpha) ') ';
      counter-increment: list;
    }
  }
`;

const TermsPage = () => {
  return (
    <Layout
      headerProps={{
        disableLogin: true,
        NavigationOptions: (
          <Nav.Link onClick={() => navigate(-1)}>
            <CloseButton />
          </Nav.Link>
        ),
      }}
    >
      <SEO title="Términos y condiciones" />

      <Container>
        <BannerContainer title="Términos y condiciones" />

        <Row>
          <Col xs="12" md="10" lg="8" className="mx-auto py-4">
            <p>
              Por favor lea cuidadosamente los Términos y Condiciones (los
              "Términos" o "Términos y Condiciones") de aDominicana antes de
              crearse una cuenta y realizar una compra o utilizar nuestros
              servicios (los "Servicios") a través del sitio web
              www.adominicana.com (el "sitio" o "sitio web"), la aplicación
              móvil de aDominicana (la "aplicación" o "aplicación móvil") o
              cualquier otro medio disponible. Estos términos representan un
              acuerdo entre usted (el "cliente" o "usted") y aDominicana. Al
              crear una cuenta y/o utilizar nuestros servicios, usted acepta
              estar sujeto a todos los términos establecidos a continuación. Los
              términos pueden ser actualizados periódicamente por aDominicana.
              Si usted está en desacuerdo con estos términos, no registre una
              cuenta con aDominicana o, de tenerla ya, inmediatamente interrumpa
              el acceso al sitio y a la aplicación, no utilice los servicios y
              notifíquelo por medio de un correo electrónico a
              soporte@adominicana.com o llamando al teléfono de atención al
              cliente al (305)376-7119.
            </p>
            <h4>Advertencia</h4>
            <p>
              Es un delito adquirir un producto o servicio por medios
              fraudulentos. Todos y cada uno de los productos/servicios
              comprados a través de este sitio web o la aplicación no se
              utilizarán para fines ilegales. En caso de que aDominicana tenga
              conocimiento de transacciones o compras fraudulentas, se reserva
              el derecho de enviar toda la información disponible a la víctima o
              a la entidad de aplicación de la ley ante cualquier solicitud
              legal, sin el consentimiento previo del cliente. Este es un
              término incondicional e innegociable, y una excepción a nuestras
              políticas de privacidad.
            </p>
            <h4>Aceptación de los términos y condiciones</h4>
            <h4 className="my-4">
              I. USO DE UNA CUENTA DE ADOMINICANA DESDE EL SITIO WEB, LA
              APLICACIÓN MÓVIL O MEDIANTE LLAMADA TELEFÓNICA.
            </h4>
            <p>
              Al crear una cuenta o utilizar el servicio de aDominicana en el
              sitio web o aplicación móvil, usted afirma que cuenta con la edad
              legal para participar en este acuerdo y que ha leído y entendido
              completamente los términos y condiciones. El cliente está de
              acuerdo que toda venta de bienes y/o servicios a través del sitio
              web y/o por la aplicación móvil de aDominicana es final y no es
              reembolsable, siempre y cuando el cliente haya realizado la orden
              a través de su cuenta en aDominicana.
            </p>
            <p>
              <b>A. Firma Electrónica</b> - Estos Términos y Condiciones están
              sujetos a la Ley Federal de Firma Electrónica de 2000, según
              enmienda, y se acuerda que constituye un acuerdo entre aDominicana
              y usted.
            </p>
            <p>
              <b>
                B. Los Términos y Condiciones se aplican a todos los servicios y
                ventas
              </b>{' '}
              - La prestación de servicios ofrecidos a través del sitio web, la
              aplicación móvil o cualquier otro medio disponible, por ejemplo, a
              través de una llamada telefónica, está sujeta a la aceptación de
              estos Términos y Condiciones. Al hacer clic en "Crear cuenta" el
              cliente acepta expresamente que quedará vinculado por los Términos
              y Condiciones aplicables a los servicios prestados por
              aDominicana, y a la Política de Privacidad. Asegúrese de leer
              estos términos detenidamente antes de acceder a los servicios de
              aDominicana o registrarse como nuevo usuario (crear una cuenta).
            </p>
            <p>
              <b>C. Ley aplicable, jurisdicción y lugar</b> - El presente
              acuerdo y los términos de uso se regirán exclusivamente y se
              interpretarán en estricta conformidad con las leyes del Estado de
              Florida, Estados Unidos, sin tener en cuenta las teorías de
              conflictos de leyes, la jurisprudencia o los principios. El
              usuario, en virtud del registro de la cuenta, el uso o la compra a
              través del sitio web/aplicación acepta someterse exclusivamente a
              la jurisdicción de los tribunales estatales y federales del
              condado de Miami-Date, y renuncia expresamente a todas las
              defensas a la jurisdicción personal de dichos tribunales.
            </p>
            <p>
              <b>D. Uso legal de la cuenta, sitio y aplicación</b> - El cliente
              acepta y asegura que no utilizará este sitio web o la aplicación
              móvil de aDominicana de alguna manera que cause, o pueda causar,
              daños o menoscabo de la disponibilidad o accesibilidad del sitio y
              de su aplicación; así como de cualquier manera que sea ilegal,
              fraudulenta o dañina, o en relación con cualquier propósito o
              actividad ilegal, fraudulenta o dañina. El cliente acepta y
              garantiza que no utilizará este sitio web para copiar, almacenar,
              alojar, transmitir, enviar, usar, publicar o distribuir cualquier
              material que consista (o esté vinculado a) cualquier software
              informático malicioso. El cliente acepta no realizar ninguna
              actividad de recopilación de datos sistemática o automatizada en o
              en relación con este sitio web sin el consentimiento previo por
              escrito de aDominicana (el correo electrónico no constituye un
              consentimiento por escrito). El cliente no utilizará este sitio
              web o aplicación móvil para transmitir o enviar comunicaciones
              comerciales no solicitadas ni para ningún propósito relacionado
              con el marketing sin el consentimiento expreso por escrito de
              aDominicana. El cliente puede ver, descargar información e
              imágenes del sitio web sólo para fines de uso personal. El cliente
              acepta que no publicará material de este sitio web (incluida la
              publicación en otro sitio web); vender, alquilar o sublicenciar
              material del sitio web; mostrar cualquier material del sitio web
              en público o al público; reproducir, duplicar, copiar o explotar
              material de este sitio web con fines comerciales; editar o
              modificar cualquier material en el sitio web; o redistribuir
              material de este sitio web (excepto el contenido disponible de
              manera específica y expresa para su redistribución). Donde y si el
              contenido está específicamente disponible para su redistribución,
              sólo se puede redistribuir dentro de su organización y el usuario
              es totalmente responsable de su uso o mal uso posterior.
            </p>
            <p>
              <b>
                E. Estos Términos y Condiciones se consideran un contrato
                electrónico
              </b>{' '}
              - Todos los servicios o productos mostrados, comercializados,
              ofrecidos o de otro modo accedidos, publicados o comprados a
              través del sitio/aplicación, se realizan bajo la responsabilidad
              del cliente y por contrato en formato electrónico. La ley federal
              de firma electrónica de EE. UU. se aplica a este acuerdo y a todas
              las transacciones a través del sitio web/aplicación de
              aDominicana. Además de esta muestra en el navegador o en la
              aplicación, aDominicana pondrá a disposición de las personas
              interesadas estos Términos y Condiciones en formato electrónico a
              solicitud expresa. Puede solicitar acceso a estos documentos
              electrónicos en Adobe Acrobat o en formatos de texto estándar. En
              todo momento el cliente se reserva el derecho de solicitar y
              recibir estos documentos previa solicitud al correo electrónico de
              aDominicana soporte@adominicana.com.
            </p>
            <p>
              <b>F. Legitimidad de los medios de pago</b> - El cliente declara y
              garantiza que es el titular autorizado de cualquier tarjeta
              bancaria registrada en su cuenta de aDominicana. Si es empleado de
              una empresa u otra entidad o está actuando en nombre de una
              empresa, debe registrar el nombre de la empresa como el usuario de
              la cuenta y debe estar autorizado por la compañía para aceptar los
              Términos y Condiciones de uso en nombre de ese empresario, empresa
              o entidad. De lo contrario y de acuerdo con estos Términos y
              Condiciones, la cuenta podrá ser deshabilitada o cancelada.
            </p>
            <h4 className="my-4">
              II. USO DE CUENTA, SITIO Y APLICACIÓN DE NUESTROS SERVICIOS.
            </h4>
            <p>
              <b>A. Configuración y cancelación de cuenta</b>
              <br />
              Lo siguiente aplica para la configuración de la cuenta de usuario.
              Cualquier duda que presente puede contactar con aDominicana
              llamando al Servicio de Atención al Cliente +1 (305)376-7119 o a
              través del correo electrónico soporte@adominicana.com.
            </p>
            <OrderedList>
              <li>
                <b>Configuración de perfil de la cuenta</b> - Para utilizar el
                servicio de aDominicana, el cliente tiene que crear una cuenta.
                Al crear una cuenta, el cliente debe proporcionar información
                veraz conforme a lo solicitado en el formulario de registro. El
                cliente acepta que la información que ofrece es verdadera,
                exacta y completa. Proporcionar información falsa, inexacta o
                engañosa al crear o actualizar una cuenta con aDominicana viola
                estos términos y puede resultar en la cancelación inmediata de
                la cuenta. Cualquier cambio que el cliente realice después de
                ser creada la cuenta, tales como el número de teléfono, deberá
                actualizarlo con la mayor brevedad posible en el Perfil de la
                cuenta y será totalmente su responsabilidad la actualización de
                dicha información. Toda la información personal, incluyendo la
                información financiera (los "datos") que el cliente proporcione,
                serán tratados de acuerdo con la Declaración de Privacidad. Una
                vez registrado, el cliente tiene un nombre de usuario y una
                contraseña y es completamente responsable de todas las
                actividades que ocurran bajo su cuenta, incluyendo el uso no
                autorizado de su(s) tarjeta(s) bancaria(s). El cliente debe
                notificar inmediatamente cualquier uso no autorizado de su
                cuenta a aDominicana llamando al +1(305)376-7119 o escribiendo
                al correo electrónico soporte@adominicana.com.
              </li>
              <li>
                <b>Configuración de una cuenta</b> - Con aDominicana puede
                crearse una cuenta a través del sitio web, la aplicación móvil o
                una llamada telefónica. Usted necesitará crear un nombre de
                usuario que responderá a una dirección de correo electrónico
                válida para relacionarla con su cuenta y necesitará ingresar una
                contraseña. La actualización de cualquier información de la
                cuenta será su responsabilidad. Debe proteger su información y
                no revelar su contraseña a terceros. Si usted olvida o pierde la
                contraseña, deberá solicitar una nueva contraseña en la sección
                "Recuperar contraseña" del sitio web o de la aplicación móvil.
                Cada dirección de correo electrónico y contraseña deben ser
                utilizados por un único usuario y no son transferibles.
              </li>
              <li>
                <b>Verificación de identidad</b> - Una vez que su cuenta ha sido
                creada y realizada la primera orden, aDominicana llevará a cabo
                un procedimiento de verificación de identidad. Los clientes de
                Estados Unidos deberán consultar en el registro de la tarjeta
                bancaria utilizada el cargo realizado por el servicio y deberán
                responder el sms recibido con el monto descontado. Las cuentas
                de clientes de otros países serán analizadas por el sistema de
                aDominicana para asegurar que no tienen relación con alguna
                transacción fraudulenta realizada anteriormente. En caso de que
                el procedimiento de verificación inicial no resulte
                satisfactorio las cuentas y las órdenes serán canceladas y se
                hará el reembolso del pago realizado. Si el procedimiento de
                verificación inicial resulta satisfactorio la cuenta
                correspondiente quedará confirmada, se dará curso a la primera
                orden y estará lista para la realización de recargas telefónicas
                y cualquier otro servicio disponible.
              </li>
              <li>
                <b>Cierre o cancelación de su cuenta</b> - El cliente puede
                cerrar su cuenta con aDominicana en cualquier momento. Debe
                enviar un email a soporte@adominicana.com desde el mismo correo
                que fue registrado en la cuenta. USTED ENTIENDE Y ACEPTA QUE
                CERRAR, ABANDONAR O CANCELAR SU CUENTA RESULTARÁ EN LA
                INCAUTACIÓN DE TODOS Y CADA UNO DE LOS CRÉDITOS Y GRATUIDADES DE
                LA CUENTA (SERVICIOS DE RECARGA PENDIENTES DE EJECUCIÓN). TODAS
                LAS COMPRAS DE CRÉDITO SON FINALES Y NO SERÁN REEMBOLSABLES. En
                el caso que usted haya creado una nueva cuenta y este sea el
                motivo de la cancelación, sus créditos para servicios de recarga
                serán transferidos a la nueva cuenta.
              </li>
            </OrderedList>
            <p>
              <b>B. Uso general de la cuenta y los servicios comprados</b>
            </p>
            <OrderedList>
              <li>
                <b>Uso para la compra/venta de servicios de comunicaciones</b> -
                aDominicana ofrece a sus clientes una plataforma para recarga de
                saldo a teléfonos móviles. Al aceptar los Términos y Condiciones
                y utilizar nuestros servicios, usted declara y garantiza que no
                utilizará el servicio de ninguna manera que viole cualquier
                derecho de autor, propiedad intelectual, u otros derechos. Las
                violaciones o posibles violaciones deberán ser inmediatamente
                reportadas al correo soporte@adominicana.com. Estos servicios se
                proporcionan "tal cual", sin ninguna garantía fuera de la que
                ellos puedan proporcionar. Aún así, aDominicana hará todo lo
                posible por proporcionar a sus clientes la mejor de las
                experiencias al hacer uso del sistema.
              </li>
              <li>
                <b>Compras de recarga de celulares</b> - Para utilizar el
                servicio de recargas a teléfonos celulares, usted se compromete
                a cumplir y llevar a cabo lo dispuesto en este acuerdo. Se le
                pedirá que introduzca el número del teléfono móvil donde se
                depositará la cantidad elegida por usted en el momento en que se
                realiza la recarga. Es su responsabilidad asegurarse de indicar
                correctamente el número del teléfono móvil al que desea enviar
                la recarga. Luego seleccionará la cantidad de dinero (en la
                moneda de destino asegurada por nuestros proveedores) que desea
                acreditar en dicho teléfono móvil. La suma total que pagará será
                reflejada claramente antes de que se le pida confirmar su
                transacción. Completar la orden es absolutamente su decisión. La
                recarga será enviada al número de teléfono móvil proporcionado
                por usted y con previa autorización de pago. Usted recibirá un
                correo electrónico con el comprobante de la orden donde se
                incluirá información sobre la(s) recarga(s) enviada(s) tan
                pronto como la transacción haya sido completada con éxito. En
                caso de que los servicios solicitados no se puedan completar con
                éxito, se cancelará la autorización y el pago no será procesado.
                Usted acepta y entiende que aDominicana sólo actúa con su previa
                autorización para enviar la(s) recarga(s) y el operador de
                telefonía móvil correspondiente al país de destino será el único
                responsable frente a usted y al destinatario de acreditar el
                saldo correspondiente a la recarga. Una vez que la recarga ha
                sido enviada a un número de teléfono móvil, puede ser utilizada
                de inmediato; sin embargo, no puede ser devuelta o retirada del
                teléfono donde se acreditó. Para evitar que usted incurra en
                este error, aDominicana le pide que confirme que el número de
                teléfono móvil introducido es correcto. Debido a lo explicado
                anteriormente, los servicios de recargas no son reversibles una
                vez que hayan sido completadas exitosamente en el país de
                destino.
              </li>
              <li>
                <b>Ventas a usuarios autorizados</b> - Cuando realiza una compra
                de manera exitosa, usted acepta que es el usuario autorizado de
                la cuenta y que toda la información que ha sido proporcionada es
                verdadera.
              </li>
              <li>
                <b>Problemas de uso</b> - Todas las dudas, quejas, reclamos o
                sugerencias deben ser dirigidas al correo electrónico
                soporte@adominicana.com o llamando al teléfono +1 (305) 376-7119
                o utilizando el servicio disponible en la aplicación móvil.
              </li>
            </OrderedList>
            <p>
              <b>C. Política de Reembolso y Devoluciones.</b>
              <br />
              Solamente podrá ser devuelto el pago de una orden que no pudo ser
              completada debido a que el teléfono no exista, o esté
              deshabilitado, y/o el proveedor local confirme que no pudo
              acreditar el saldo. Si el cliente califica para un reembolso, una
              vez que haya sido procesado del lado de aDominicana, puede demorar
              hasta 30 días hábiles en aparecer reflejado en el registro de la
              tarjeta bancaria utilizada, dependiendo de la operación de las
              entidades financieras involucradas en la transacción. Si la orden
              fue procesada exitosamente con la información provista por el
              cliente, la misma no califica para un reembolso, siendo el cliente
              el máximo responsable por cualquier error en el número de teléfono
              y/o cuenta de destino. En caso de que no pueda ser completada una
              orden y antes de emitirse el reembolso, el cliente puede
              comunicarse con el Servicio de Atención al Cliente de aDominicana
              por medio del teléfono o el correo electrónico e indicar otro
              teléfono/cuenta al que enviar el saldo correspondiente por el pago
              realizado.
            </p>
            <h4 className="my-4">III. TÉRMINOS Y CONDICIONES GENERALES.</h4>
            <p>
              <b>
                A. Uso de cookies por el sitio web o la aplicación y la política
                de privacidad
              </b>{' '}
              - El sitio web de aDominicana utiliza cookies en el desempeño de
              los servicios y la operación. Al utilizar el sitio web y aceptar
              los Términos y Condiciones de aDominicana, el cliente acepta que
              aDominicana puede usar cookies de acuerdo con su Política de
              Privacidad, la cual se incorpora en este acuerdo como referencia.
            </p>
            <p>
              <b>
                B. No hay garantías en el servicio de terceros a través del
                sitio o aplicación
              </b>{' '}
              - SUPLEMENTO A LOS TÉRMINOS ANTERIORES: ADOMINICANA NO OFRECE
              NINGÚN TIPO DE GARANTÍA EXPRESA O IMPLÍCITA, INCLUYENDO, PERO NO
              LIMITADA A GARANTÍAS DE COMERCIABILIDAD O IDONEIDAD PARA UN
              PROPÓSITO EN PARTICULAR CON RESPECTO A LAS DESCARGAS Y DESCUENTOS
              DE TERCEROS (SERVICIO DE TERCEROS). TAMPOCO SERÁ RESPONSABLE DE
              CUALQUIER COSTO O DAÑO QUE SURJA DIRECTA O INDIRECTAMENTE DE
              CUALQUIER TRANSACCIÓN DE SERVICIO DE TERCEROS, EL USO DEL SITIO
              WEB Y SOFTWARE SUBYACENTE. ADOMINICANA NO GARANTIZA QUE LA
              APLICACIÓN Y EL SOFTWARE SUBYACENTE DE SU SITIO O SU APLICACIÓN
              MÓVIL ESTÉN LIBRES DE ERRORES O FUNCIONARÁN SIN FALLA O
              INTERRUPCIÓN, O QUE CUALQUIER DEFECTO EN LA APLICACIÓN Y EL
              SOFTWARE SUBYACENTE SERÁ CORREGIDO POR EL TITULAR DEL SOFTWARE
              BAJO GARANTÍA.
            </p>
            <p>
              <b>C. Limitación de responsabilidad expresada</b> - EN NINGÚN CASO
              ADOMINICANA SERÁ RESPONSABLE ANTE EL USUARIO POR (I) CUALQUIER
              DAÑO INCIDENTAL, CONSECUENTE O INDIRECTO (INCLUYENDO, PERO SIN
              LIMITARSE, A DAÑOS POR INTERRUPCIÓN, POR PÉRDIDAS, PÉRDIDAS DE
              PROPIEDAD O IGUAL) DERIVADAS DEL USO DE O INCAPACIDAD PARA
              UTILIZAR LA APLICACIÓN Y EL SOFTWARE SUBYACENTE, INCLUSO SI
              ADOMINICANA O SUS REPRESENTANTES AUTORIZADOS HAN SIDO NOTIFICADOS
              DE LA POSIBILIDAD DE DICHOS DAÑOS, O (II) CUALQUIER RECLAMO
              ATRIBUIBLE A ERRORES, OMISIONES U OTRAS INEXACTITUDES DE UN
              SOFTWARE, A TRAVÉS DEL SITIO WEB O LA APLICACIÓN MÓVIL.
            </p>
            <p>
              <b>D. Indemnización</b> - El usuario/cliente acepta indemnizar,
              defender y mantener a aDominicana, sus funcionarios, directores,
              empleados, agentes, accionistas, licenciantes, proveedores, socios
              comerciales y cualquier beneficiario efectivo, de y contra todas
              las pérdidas, gastos, daños y costos, incluidos los honorarios
              razonables de abogados, resultantes del uso de este sitio web por
              parte del usuario; incluida, entre otras, cualquier violación de
              este Acuerdo por parte del usuario. Las disposiciones de este
              párrafo son para beneficio de aDominicana y sus funcionarios,
              directores, empleados, agentes, accionistas, otorgantes de
              licencias, proveedores, socios comerciales y beneficiarios reales
              con respecto a cualquier producto vendido o comprado a través del
              sitio web de aDominicana. Cada una de estas personas o entidades
              tendrá derecho a hacer valer y cumplir estas disposiciones
              directamente contra el cliente en su propio nombre.
            </p>
            <p>
              <b>E. Dispositivos, software y planes de datos</b> - Los servicios
              de aDominicana pueden requerir ciertos dispositivos (por ejemplo:
              computadoras, audífonos, bocinas, micrófonos, teléfonos celulares,
              etc), software, conexión a Internet, planes de celulares y de
              datos. Es responsabilidad del cliente disponer de todos los
              dispositivos, conexiones y planes necesarios para utilizar los
              servicios de aDominicana y pagar todas las tarifas que cobren
              terceros e impuestos relacionados con el uso que realice de
              nuestros servicios. El cliente deberá verificar con sus
              proveedores de Internet y compañías de servicios telefónicos las
              tarifas que pudieran corresponder por el uso de los servicios de
              aDominicana.
            </p>
            <p>
              <b>F. Políticas de aDominicana.</b>
            </p>
            <OrderedList>
              <li>
                <b>Política de privacidad</b> - aDominicana, honrando el acuerdo
                asumido con sus clientes en los Términos y Condiciones, cumplirá
                con la protección de los datos de sus clientes basándose en la
                Política de Privacidad.
                <OrderedAlphaList className="mt-3">
                  <li>
                    La información personal del cliente será tratada de forma
                    confidencial y no será revelada a terceros. Una vez
                    registrada la información personal del cliente, la misma
                    quedará sujeta a los términos de la Política de Privacidad,
                    y excepto en el caso de que dicha revelación sea necesaria
                    por imposición de la ley o en virtud de un acuerdo o
                    petición emitida por el Regulador Fiscal u otra autoridad
                    legal o reguladora competente, deberá ser accesible sólo
                    para nosotros, nuestros agentes, o por una empresa
                    controlada por nosotros; y será procesada o utilizada por
                    nosotros para los propósitos del servicio que brindamos (y
                    que el cliente solicita), y de una manera compatible con el
                    cumplimiento de nuestra obligación en virtud del Acuerdo. La
                    información personal del cliente se archiva en nuestro
                    servidor de manera encriptada para proteger su privacidad y
                    brindar la mayor seguridad.
                  </li>
                  <li>
                    Información que recopilamos y permisos.
                    <br />
                    Recopilamos la siguiente información de los clientes de
                    manera directa o indirecta:
                    <ul>
                      <li>Nombres y apellidos.</li>
                      <li>Correo electrónico.</li>
                      <li>País de residencia.</li>
                      <li>Número de teléfono.</li>
                      <li>Métodos de pago.</li>
                      <li>Dirección particular.</li>
                      <li>Historial de compras en aDominicana.</li>
                      <li>
                        Contactos telefónicos a los que se envían las recargas..
                      </li>
                      <li>Dirección ip del dispositivo utilizado.</li>
                    </ul>
                    <p>
                      Esta información personal es necesaria para brindar
                      nuestros servicios y darle una experiencia personalizada a
                      nuestro cliente. Se pedirá al usuario su aceptación de
                      nuestros Términos y Condiciones y de nuestra Política de
                      Privacidad para guardar su información personal y crear su
                      cuenta en aDominicana. El cliente puede crear sus propios
                      contactos dentro de la aplicación de aDominicana
                      completando un formulario y de manera opcional puede
                      auxiliarse de la lista de contactos que ya tiene
                      almacenada en su teléfono, tableta u otro dispositivo. La
                      aplicación móvil de aDominicana pedirá permiso al cliente
                      para acceder a esos contactos que ya están previamente
                      almacenados en el dispositivo. En el servidor de
                      aDominicana sólo quedarán almacenados los contactos
                      creados por el cliente dentro de la aplicación. Los
                      contactos que previamente estaban registrados en el
                      dispositivo del cliente no serán almacenados en el
                      servidor de aDominicana.
                    </p>
                  </li>
                  <li>
                    Uso de la información personal del cliente.
                    <br />
                    Podemos usar esta información para lo siguiente:
                    <ul>
                      <li>
                        Para que el cliente tenga sus contactos frecuentes, los
                        que ha recargado presentados de una manera más
                        accesible, independientemente de la plataforma que esté
                        utilizando, sitio web o aplicación, para hacer una
                        recarga.
                      </li>
                      <li>
                        Para brindar al cliente información sobre productos o
                        servicios que le puedan interesar.
                      </li>
                      <li>
                        Para notificar al cliente acerca de cualquier cambio en
                        nuestro sitio web/aplicación o en nuestros servicios.
                      </li>
                      <li>
                        Para asegurarnos que el contenido de nuestro sitio
                        web/aplicación sea presentado al cliente de la manera
                        más efectiva.
                      </li>
                      <li>Para brindar la mejor atención al cliente.</li>
                      <li>
                        Para propósitos de seguridad y prevención de fraude.
                      </li>
                      <li>Para análisis de marketing.</li>
                    </ul>
                  </li>
                  <li>
                    Eliminar la información personal del cliente de nuestro
                    sistema.
                    <br />
                    El cliente puede eliminar su cuenta del sistema de
                    aDominicana cuando lo desee. Para ello debe comunicar su
                    solicitud de eliminación de cuenta al equipo de atención al
                    cliente a través del correo electrónico
                    soporte@adominicana.com o llamando al teléfono
                    +1(305)376-7119. El cliente deberá utilizar el mismo correo
                    electrónico y/o el mismo número telefónico registrado en su
                    cuenta. En un breve intervalo de tiempo toda la información
                    personal del cliente será eliminada del registro de
                    aDominicana.
                  </li>
                </OrderedAlphaList>
                <p>
                  Al utilizar nuestros servicios y aceptar nuestros Términos y
                  Condiciones, el cliente acepta esta Política de Privacidad.
                </p>
              </li>
              <li>
                <b>Política de reclamaciones bancarias</b> - Si existiera alguna
                duda referente a alguna transacción completada en su cuenta, le
                rogamos que se ponga en contacto con nuestro servicio de
                Atención al Cliente antes de presentar una reclamación al banco.
                De esta manera podemos ayudarle y dar solución a su problema sin
                incurrir en litigios, cargos bancarios y acortando además el
                tiempo de resolución del problema.
              </li>
              <li>
                <b>En caso de una reclamación bancaria a la empresa</b> -
                aDominicana tendrá el derecho de deshabilitar el acceso a su
                cuenta durante el periodo en que se lleve a cabo la
                investigación de los hechos declarados en la reclamación
                bancaria y, una vez terminado el proceso, aDominicana le
                contactará para dar a conocer los resultados de la investigación
                y las particularidades del uso de su cuenta a partir de ese
                momento. Una vez terminado el proceso, aDominicana tiene el
                derecho de inhabilitar permanente su cuenta de usuario, así como
                impedir las recargas a los números celulares que tengan alguna
                relación con su cuenta. Ante un comportamiento irregular y uso
                indebido del sistema, aDominicana finalizará permanentemente las
                relaciones con el cliente involucrado. Ante la duda que pueda
                surgir sobre una transacción realizada por usted o por alguna
                persona autorizada al utilizar su cuenta, solicitamos que se
                comunique con aDominicana lo antes posible, antes de hacerlo con
                sus instituciones bancarias. De esa forma podremos cooperar
                prontamente en el proceso de resolución del problema.
              </li>
            </OrderedList>
            <p>
              <b>G. Tarifas y métodos de pagos.</b>
            </p>
            <OrderedList>
              <li>
                <b>Transacciones con tarjetas de débito o crédito</b> - Los
                pagos se realizan con tarjetas de débito y/o crédito VISA,
                MasterCard o DISCOVER. Otro método de pago como: tarjeta de
                regalo, tarjeta prepagada, efectivo o cualquier otro medio, no
                será aceptado. Todos estos métodos de pago autorizados deben
                tener una dirección de facturación y un banco emisor de la
                tarjeta válida. Una vez solicitada una recarga, se procede a la
                autorización de la transacción. Tras verificar que existen
                fondos disponibles en la tarjeta se envía la recarga y, después
                de recibir la confirmación de entrega, aDominicana procederá a
                la recepción de los fondos previamente autorizados.
                Seguidamente, el usuario recibirá una confirmación vía email de
                que la transacción resultó exitosa. En caso de no poder ejecutar
                correctamente la recarga solicitada por el cliente, se cancelará
                la autorización previa y la tarjeta del cliente no será
                procesada. aDominicana no está obligado a realizar ningún tipo
                de autentificación o verificación de identidad adicionales a
                otras que no sean las que aDominicana considere oportunas y
                suficientes para proteger la seguridad y mantener el buen uso de
                este sitio web y/o la aplicación móvil. Todos los débitos
                concluidos después de una autentificación o verificación de
                identidad correctas son responsabilidad del usuario únicamente.
                aDominicana aceptará responsabilidad por la no ejecución o
                ejecución defectuosa de recargas hechas a través del sitio web o
                la aplicación móvil, sujeto a su adhesión a este Acuerdo, el uso
                correcto del sitio web siguiendo las instrucciones de
                aDominicana, y la ausencia de cualquier falsedad, fraude o
                negligencia por parte del cliente. Dicha responsabilidad, si
                ocurriere, se limitará estrictamente al valor de la recarga por
                transacciones no ejecutadas o defectuosas.
              </li>
              <li>
                <b>Tarifas y cargos de aDominicana</b> - aDominicana cobra
                exactamente el monto mostrado al final de su transacción. No
                existen cargos ocultos. Además de las tarifas por procesamiento
                y servicios, los proveedores externos pueden cobrar por la
                utilización de los Servicios o por las conversiones de moneda.
                Las tarifas de los proveedores externos pueden variar según las
                compañías de servicios telefónicos, socios locales, empresas
                locales de telecomunicaciones, operadores de teléfonos
                celulares, ubicación y tipo de servicios. Es su responsabilidad
                averiguar si los usuarios de los Servicios o quienes adquieran
                créditos incurrirán en cargos de los proveedores externos y
                deberán pagar dichos cargos. aDominicana no asume
                responsabilidad alguna por cualquier discrepancia entre la
                información sobre tarifas que aparece en el sitio y las que en
                realidad sean cobradas por su proveedor. Las tarifas de
                aDominicana y otros proveedores también se aplicarán a los
                periodos de promociones.
              </li>
              <li>
                <b>Promociones</b> - aDominicana ofrecerá promociones y campañas
                para todos los usuarios que hayan dado su consentimiento para
                recibir material de propaganda. La información acerca de las
                promociones estará disponible en el sitio web, la aplicación
                móvil y se enviará a través de correos electrónicos, SMS o
                notificaciones PUSH. Se incluirá en cada campaña la fecha
                promocional, condiciones y limitaciones en caso de existir
                alguna. Estas campañas pueden ofrecer promociones especiales por
                parte de aDominicana, por lo que las promociones pueden ser
                limitadas en tiempo y uso de los beneficios que la misma ofrece.
                Los Términos y Condiciones generales para promociones y campañas
                incluyen, pero no se limitan a:
                <OrderedAlphaList className="mt-3">
                  <li>
                    Ciertas ofertas que sólo serán válidas para los nuevos
                    usuarios al inscribirse (cancelar una cuenta y/o luego
                    registrarse nuevamente con el mismo número telefónico o
                    email no será considerado como un nuevo registro).
                  </li>
                  <li>
                    Todos los pagos realizados en el marco de las campañas de
                    recargas a móviles no son reembolsables ni transferibles.
                  </li>
                  <li>
                    aDominicana se reserva el derecho de rechazar o concluir una
                    campaña en cualquier momento y por cualquier razón, a
                    criterio absoluto de aDominicana.
                  </li>
                  <li>
                    aDominicana se reserva el derecho de modificar, añadir o
                    eliminar las condiciones de cualquier oferta luego de ser
                    promocionada.
                  </li>
                </OrderedAlphaList>
              </li>
            </OrderedList>
            <p>
              <b>H. COMUNICACIÓN Y NOTIFICACIONES</b> - aDominicana enviará
              notificaciones y comunicaciones usando la información de contacto
              almacenada en el perfil de su cuenta. Sus datos podrán ser usados
              para notificarle sobre información referente a nuestras ofertas,
              al estado de sus transacciones y cualquier otra información de
              utilidad. El cliente puede aceptar o no recibir estas
              notificaciones. De la misma manera si en algún momento desea dejar
              de recibir futuras notificaciones, se incluyen instrucciones
              precisas de cómo suspender el servicio al final de cada correo
              electrónico o SMS y en la sección Perfil de la aplicación móvil.
              Esta es la manera en que puede revocar el permiso otorgado
              anteriormente a aDominicana para recibir mensajes de texto o de
              correo electrónico. Puede solicitar también dejar de recibir
              notificaciones escribiendo al correo electrónico:
              soporte@adominicana.com.
            </p>
            <p>
              <b>I. SUSPENSIÓN DEL SERVICIO</b> - aDominicana puede terminar
              este Acuerdo y el cliente abandonar el uso del sitio web y/o de
              los servicios prestados si: ocurriese un fallo o un mal
              funcionamiento en cualquier parte del Sistema utilizado en
              relación con el sitio web o la aplicación móvil. De la misma
              manera se procederá si estuviera en riesgo la seguridad del
              Sistema. Dicha suspensión puede ser de carácter temporal,
              considerando un periodo razonable hasta la solución del problema
              sin incurrir en ninguna responsabilidad hacia el cliente.
            </p>
            <p>
              <b>J. SU CONTRIBUCIÓN</b> - Al enviar a aDominicana cualquier
              comentario, sugerencia, idea y/o materiales en relación con el
              sitio web, la aplicación o los servicios prestados, usted acepta
              que podemos utilizar, reproducir, publicar, modificar, adaptar y
              transmitir dicha comunicación de forma gratuita a terceros y sin
              restricciones, sin perjuicio de las obligaciones según lo
              dispuesto en la Declaración de Privacidad.
            </p>
            <p>
              <b>K. SEGURIDAD, MANTENIMIENTO Y DISPONIBILIDAD</b> - Con el
              objetivo de mejorar nuestros servicios y proporcionar una mayor
              calidad a los clientes, aDominicana puede, por razones de
              seguridad, de mantenimiento (ya sean de emergencia o
              planificadas), actualizaciones u otras razones, interrumpir
              ciertos o todos los Servicios al cliente; y/o demorar la
              implementación de cualquier Servicio nuevo; y/o retirar, sustituir
              o volver a emitir contraseñas; y/o cambiar procedimientos de
              autentificación o procesos para acceder al sitio web o los
              Servicios. Siempre haremos todo lo posible por minimizar las
              molestias ocasionadas.
            </p>
            <p>
              <b>L. TERMINACIÓN DEL ACUERDO</b> - aDominicana puede concluir
              este Acuerdo y retirar el uso del sitio web y/o de los servicios
              prestados inmediatamente después del incumplimiento de cualquiera
              de los Términos o cuando existan fundadas razones para la
              terminación del mismo. De la misma manera se finalizará si existen
              motivos para creer que cualquiera de los servicios han sido
              prestados por negligencia, de manera ilegal o fraudulenta por
              parte del cliente o por un tercero como consecuencia de su
              negligencia o imprudencia. Una vez tomada la decisión, el cliente
              será notificado por escrito, vía email. Este Acuerdo no tiene un
              mínimo o duración finita y seguirá siendo obligatorio para las
              partes hasta que termine. aDominicana se reserva el derecho de
              procesar o cancelar cualquier transacción en curso en la
              terminación de este Acuerdo o en la suspensión o retirada de los
              Servicios. aDominicana no se hace responsable de cualquier pérdida
              que pueda incurrir como resultado de cualquier transacción que no
              se procese como parte de los Servicios después de la terminación
              del Acuerdo o después de cualquier suspensión o retirada de los
              Servicios.
            </p>
            <p>
              <b>M. VARIACIONES DEL ACUERDO</b> - aDominicana se reserva el
              derecho a modificar este Acuerdo. Los cambios se harán efectivos
              en un periodo hábil de hasta quince (15) días después de notificar
              al cliente de tal modificación mediante correo postal, correo
              electrónico, o a través de la publicación de un mensaje en el
              sitio web. El cliente estará en todo el derecho, al recibir el
              aviso de cualquier modificación al presente Acuerdo, de
              finalizarlo de manera inmediata mediante notificación por escrito
              a soporte@adominicana.com, sin perjuicio de los derechos u
              obligaciones que hayan surgido antes de la fecha de terminación,
              incluida la responsabilidad por cualquier endeudamiento u otra
              obligación financiera, legal, o de otro tipo que se ha presentado
              con anterioridad a la fecha de terminación.
            </p>
            <p>
              <b>N. PROPIEDAD INTELECTUAL</b> - aDominicana posee todos los
              derechos en cualquier logotipo que lo acompaña, así como nombres
              del dominio https://www.adominicana.com. De igual manera, se le
              atribuyen derechos de propiedad en todos los datos, información,
              Sistemas u otros materiales utilizados para la prestación de los
              Servicios, siendo aDominicana o sus licenciados el único
              propietario. El cliente sólo deberá hacer uso de los mismos con el
              propósito de recibir los servicios tal y como se refleja en el
              Acuerdo. Cualquier reproducción de los contenidos del sitio web en
              cualquier forma sin consentimiento previo, está prohibido. Ante el
              incumplimiento o violación de dicho acuerdo aDominicana tiene todo
              el derecho a solicitar protección con respecto a cualquiera de los
              derechos antes mencionados.
            </p>
            <p>
              <b>O. SERVICIO AL CLIENTE</b> - Ante cualquier inquietud, queja o
              sugerencia usted puede contactarnos a través del correo
              electrónico soporte@adominicana.com. También puede llamar a
              nuestro Servicio de Atención al Cliente +1 (305)376-7119.
            </p>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default TermsPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
